<template>
  <section id="section-values" v-scrollanimation="'up'">
    <div class="section-header">
      <div class="nstra-container">
        <h3 class="section-title">
          Some values in
          <span class="text-primary">our company.</span>
        </h3>
      </div>
    </div>
    <div class="section-body">
      <div
        class="values-list"
        v-match-heights="{
          el: ['.card-values'],
        }"
      >
        <vue-slick-carousel v-bind="settings">
          <div class="values-item">
            <div class="card card-values">
              <div class="card-overlay"></div>
              <div class="card-img-text">
                <span class="text-1">C</span>
                <span>C</span>
              </div>
              <div class="card-body">
                <h5 class="card-title">Collaboration</h5>
                <p class="card-text">
                  Embedded with our clients’ teams, we ensure faster delivery, clearer progress visibility and comprehensive knowledge sharing.
                </p>
              </div>
            </div>
          </div>

          <div class="values-item">
            <div class="card card-values">
              <div class="card-overlay"></div>
              <div class="card-img-text">
                <span class="text-2">T</span>
                <span>T</span>
              </div>
              <div class="card-body">
                <h5 class="card-title">Transparancy</h5>
                <p class="card-text">
                  We value providing clear communication and access to information for our partners. This means asking and answering questions and being open to feedback.
                </p>
              </div>
            </div>
          </div>

          <div class="values-item">
            <div class="card card-values">
              <div class="card-overlay"></div>
              <div class="card-img-text">
                <span class="text-3">O</span>
                <span>O</span>
              </div>
              <div class="card-body">
                <h5 class="card-title">Ownership</h5>
                <p class="card-text">
                  We are committed to the responsibility of acting as the voice of our clients to ensure their vision and values.
                </p>
              </div>
            </div>
          </div>
        </vue-slick-carousel>
      </div>
    </div>
  </section>
</template>

<script>
import Vue from "vue";
import VueSlickCarousel from "vue-slick-carousel";
import VueMatchHeights from "vue-match-heights";
Vue.use(VueMatchHeights);

export default {
  components: {
    VueSlickCarousel,
  },
  data: () => ({
    settings: {
      arrows: false,
      dots: true,
      dotsClass: "slick-dots slider-dots-custom",
      edgeFriction: 0.35,
      infinite: false,
      speed: 1000,
      slidesToShow: 3,
      slidesToScroll: 1,
      focusOnSelect: true,
      swipeToSlide: true,
      centerMode: true,
      responsive: [
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 1,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    },
  }),
};
</script>
